<template>
  <YLayoutBodyMenu>
    <el-card style="min-height: 45rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form v-loading="loading" :model="form" label-width="12rem" style="padding: 2rem;">
            <el-form-item label="微信支付商户号">
              <el-input v-model="form.mch_id"></el-input>
            </el-form-item>
            <el-form-item label="微信支付密钥">
              <el-input v-model="form.api_key"></el-input>
            </el-form-item>
            <el-form-item label="支付证书序列号">
              <el-input v-model="form.wx_pay_serial_no"></el-input>
            </el-form-item>
            <el-form-item label="支付V3密钥">
              <el-input v-model="form.wx_pay_api_v3_key"></el-input>
            </el-form-item>
            <el-form-item label="启用商家转账到零钱">
              <el-switch v-model="form.wx_pay_cash_use_v3"></el-switch>
              <div class="y-desc">开启后佣金打款由 企业付款到零钱 切换为 商家转账到零钱</div>
            </el-form-item>
            <el-form-item label="支付授权目录">
              {{ payment_dir }}
            </el-form-item>
            <el-form-item>
              <el-button @click="submit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form v-loading="pemLoading" label-width="8rem" style="padding: 2rem;">
            <el-form-item label="apiclient_cert.pem">
              <y_upload_wx_cert @load="loadPem" mode="cert" v-model="cert"></y_upload_wx_cert>
            </el-form-item>
            <el-form-item label="apiclient_key.pem">
              <y_upload_wx_cert @load="loadPem" mode="key" v-model="key"></y_upload_wx_cert>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </YLayoutBodyMenu>
</template>

<script>
import y_upload_wx_cert from "@/components/y-form/y-upload/y_upload_wx_cert";
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "wxPay",
  components: {YLayoutBodyMenu, y_upload_wx_cert},
  data() {
    return {
      form: {
        mch_id: "",
        api_key: "",

        wx_pay_cash_use_v3:false,
        wx_pay_api_v3_key:"",
        wx_pay_serial_no:"",
      },
      loading: false,
      payment_dir: "",
      pemLoading: false,
      cert: false,
      key: false,
    }
  },
  mounted() {
    this.load();
    this.loadPem();
    this.payment_dir = location.origin + "/"
  },
  methods: {
    loadPem() {
      this.pemLoading = true;
      this.$u.api.attach.wxPayPemLoad().then(res => {
        this.pemLoading = false;
        this.cert = res.cert;
        this.key = res.key;
      })
    },
    load() {
      this.loading = true;
      this.$u.api.system.wechatPayment().then(res => {
        this.form = res;
        this.loading = false;
      })
    },
    submit() {
      this.$u.api.system.wechatPaymentEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>
.y-desc {
  line-height: 1rem
}
</style>